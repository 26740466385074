export type WorkItem = {
  name: string;
  status?: string;
  plannedStartDate?: string;
  plannedCompletionDate?: string;
  actualStartDate?: string;
  actualCompletionDate?: string;
  projectedStartDate?: string;
  projectedCompletionDate?: string;
  lastUpdateDate?: string;
};

export default function normalizeReportingData(
  reportingData: any[]
): WorkItem[] {
  let workItemType = "";
  const allWorkItems = reportingData.reduce((acc, group) => {
    if (workItemType === "") {
      workItemType = group.key.split("_")[0];
    }

    if (group && group.children) {
      return acc.concat(group.children);
    }

    return acc;
  }, []);

  return allWorkItems.map((workItem: any) => {
    return Object.keys(workItem).reduce((acc, key) => {
      if (key !== "__typename") {
        const keyType = key.split("_")[1];

        if (keyType === undefined) {
          acc[key] = workItem[key];
        } else {
          acc[keyType] = workItem[key];
        }
      }

      if (acc["workItemType"] === undefined) {
        acc["workItemType"] = workItemType;
      }

      return acc;
    }, {} as any);
  });
}
