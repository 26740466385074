import { Event } from "react-big-calendar";
import { ConfigTypes } from "../context/AppState";
import { WorkItem } from "./normalizeReportingData";

function createEvent(
  workItem: WorkItem,
  color: string,
  start: string,
  end?: string
): Event {
  return {
    title: workItem.name,
    start: new Date(start),
    end: new Date(end || start),
    resource: {
      color,
      ...workItem,
    },
  };
}

export default function createEvents(data: WorkItem[], config: ConfigTypes) {
  return data.reduce((acc, workItem) => {
    if (workItem.plannedStartDate && config.planned.visible) {
      acc.push(
        createEvent(
          workItem,
          config.planned.color,
          workItem.plannedStartDate,
          workItem.plannedCompletionDate
        )
      );
    }

    if (workItem.projectedStartDate && config.projected.visible) {
      acc.push(
        createEvent(
          workItem,
          config.projected.color,
          workItem.projectedStartDate,
          workItem.projectedCompletionDate
        )
      );
    }

    if (workItem.actualStartDate && config.actual.visible) {
      acc.push(
        createEvent(
          workItem,
          config.actual.color,
          workItem.actualStartDate,
          workItem.actualCompletionDate
        )
      );
    }

    return acc;
  }, [] as Event[]);
}
