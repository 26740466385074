export function getReportId() {
  try {
    return window.location.pathname.split("/")[3];
  } catch (e) {
    console.error(e);
    return "";
  }
}

export default function getReportInfoFromUrl() {
  const urlParams = new URLSearchParams(window.location.search);

  return {
    reportId: getReportId(),
    blockId: urlParams.get("blockId") || "",
    columnValues: urlParams.get("columnValues")?.split(",") || [],
  };
}
