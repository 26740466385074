import React, { useEffect, useState } from "react";
import {
  Calendar as BigCalendar,
  Event,
  momentLocalizer,
} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import styled from "styled-components";
import { useAppState } from "../context/AppState";
import createEvents from "../utils/createEventsFromData";

const localizer = momentLocalizer(moment);

function Calendar() {
  const { data, configTypes } = useAppState();
  const [events, setEvents] = useState<Event[]>([]);

  useEffect(() => {
    if (data && configTypes) {
      setEvents(createEvents(data, configTypes));
    }
  }, [data, configTypes]);

  return (
    <Wrapper data-testid="big-calendar">
      <BigCalendar
        localizer={localizer}
        events={events}
        eventPropGetter={(event, start, end, isSelected) => ({
          event,
          start,
          end,
          isSelected,
          style: { backgroundColor: event.resource.color },
        })}
        onSelectEvent={(event) => {
          window.open(
            `/${event.resource?.workItemType}/${event.resource?.extRefId}`,
            "_blank"
          );
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;

export default Calendar;
