import { useQuery } from "react-query";

const REPORTING_URL =
  "https://review-reporting-223844.dev.us-west-2.aws.wfk8s.com/graphql";

export const createReportQuery = (columnValues: string[]) => `
query getGroupedReportableDataForBlock($input: GroupedReportableBlockInput!) {
    getGroupedReportableDataForBlock(input: $input) {
      reportTitle
      blockTitle
      results {
        ... on GroupedParent {
          count
          value
          key
          children {
            ... on ReportableData {
                ${columnValues}
            }
          }
        }
      }
    }
  } 
`;

export default function useFetchReportingData(
  reportId: string,
  blockId: string,
  columnValues: string[]
) {
  return useQuery(["fetch-reporting-data"], () => {
    return fetch(REPORTING_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        query: createReportQuery(columnValues),
        variables: { input: { reportId, blockId } },
      }),
    }).then((res) => res.json());
  });
}
