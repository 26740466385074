import React from "react";
import { Provider, defaultTheme } from "@adobe/react-spectrum";
import LocalizationProvider from "./localization";
import AppStateProvider from "./context/AppState";
import { QueryCacheProvider } from "./context/QueryCache";
import App from "./components/App";

export default function Root() {
  return (
    <LocalizationProvider>
      <QueryCacheProvider>
        <Provider theme={{ ...defaultTheme, dark: undefined }}>
          <AppStateProvider>
            <App />
          </AppStateProvider>
        </Provider>
      </QueryCacheProvider>
    </LocalizationProvider>
  );
}
