import React, { ReactNode } from "react";
import { LocalizationProvider as WFLocalizationProvider } from "@wf-mfe/localize-react";

export type LocalizationProps = {
  children: ReactNode;
};

export default function LocalizationProvider(props: LocalizationProps) {
  return (
    <WFLocalizationProvider
      clientFactory={() => ({
        // url: 'http://localhost:3000', // local localizer
        namespace: "reporting-calendar-view",
      })}
    >
      {props.children}
    </WFLocalizationProvider>
  );
}
