import React from "react";
import styled from "styled-components";

import Calendar from "./Calendar";
import SidePanel from "./SidePanel";

function App() {
  return (
    <Wrapper>
      <SidePanel />
      <Calendar />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: white;
  height: 100vh;
  width: 100%;
  padding: 8px;
`;

export default App;
