import { createReactLifecycles } from "single-spa-workfront";
import Root from "./root.component";

const lifecycles = createReactLifecycles({
  rootComponent: Root,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
