import React, { useEffect, useState } from "react";
import { Flex, CheckboxGroup, Checkbox } from "@adobe/react-spectrum";
import { ConfigTypes, useAppState } from "../context/AppState";
import { WorkItem } from "../utils/normalizeReportingData";
import styled from "styled-components";

function SidePanel() {
  const { data, configTypes, setAppState } = useAppState();
  const [selected, setSelected] = useState<string[]>([]);

  useEffect(() => {
    if (configTypes) {
      setSelected(
        Object.keys(configTypes).filter(
          (config) =>
            configTypes[config as unknown as keyof ConfigTypes].visible
        )
      );
    }
  }, [configTypes]);

  /**
   * Helpers
   */
  const dataTypeExists = (data: WorkItem[], config: string): boolean => {
    const configKey = config === "updated" ? "update" : config;
    return data.some((item) =>
      Object.keys(item).some((property) =>
        property.toLowerCase().includes(configKey.toLowerCase())
      )
    );
  };

  /**
   * Handlers
   */
  const onChangeSelected = (selectedKeys: string[]) => {
    setSelected(selectedKeys);
    if (configTypes !== undefined && setAppState !== undefined) {
      setAppState({
        configTypes: Object.keys(configTypes).reduce(
          (prev, current) => ({
            ...prev,
            [current]: {
              ...configTypes[current as unknown as keyof ConfigTypes],
              visible: selectedKeys.includes(current),
            },
          }),
          configTypes
        ),
      });
    }
  };

  /**
   * Render
   */
  return (
    <Wrapper>
      <CheckboxGroup
        label="Calendars"
        value={selected}
        onChange={onChangeSelected}
      >
        {Object.keys(configTypes ?? {})
          .filter((config) => {
            if (data !== undefined) {
              return dataTypeExists(data, config);
            }
            return false;
          })
          .map((key, index) => {
            // const config = configTypes [key as unknown as keyof ConfigTypes];
            return (
              <Checkbox key={index} value={key}>
                {key}
              </Checkbox>
            );
          })}
      </CheckboxGroup>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 10px;
  min-width: 200px;
`;

export default SidePanel;
