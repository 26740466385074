import React, { FC } from "react";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const QueryCacheProvider: FC = ({ children }) => (
  <QueryClientProvider client={queryClient} children={children} />
);
