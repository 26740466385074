import React, {
  createContext,
  ReactChild,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import useFetchReportingData from "../hooks/useFetchReportingData";
import getReportInfoFromUrl from "../utils/getReportInfoFromUrl";
import normalizeReportingData, {
  WorkItem,
} from "../utils/normalizeReportingData";

export type ConfigType = {
  visible: boolean;
  color: string;
};

export type ConfigTypes = {
  planned: ConfigType;
  projected: ConfigType;
  actual: ConfigType;
  updated: ConfigType;
};

export type AppState = {
  data: WorkItem[];
  configTypes: ConfigTypes;
};

type FullAppState = AppState & {
  setAppState: (next: Partial<AppState>) => void;
};

const AppContext = createContext<Partial<FullAppState>>({});

const defaultState = {
  configTypes: {
    planned: {
      visible: true,
      color: "#9f60f3",
    },
    projected: {
      visible: true,
      color: "#ec5a96",
    },
    actual: {
      visible: true,
      color: "#59c896",
    },
    updated: {
      visible: false,
      color: "#0d66d0",
    },
  },
};

type AppStateProviderProps = {
  children?: ReactChild;
};

function AppStateProvider({ children }: AppStateProviderProps) {
  const { reportId, blockId, columnValues } = getReportInfoFromUrl();
  const { isLoading, data } = useFetchReportingData(
    reportId,
    blockId,
    columnValues
  );
  const [state, setState] = useState<Partial<FullAppState>>({
    ...defaultState,
  });
  const setAppState = useCallback(
    (next: Partial<FullAppState>) =>
      setState((prev) => Object.assign({}, prev, next)),
    [setState]
  );

  useEffect(() => {
    if (data) {
      setAppState({
        data: normalizeReportingData(
          data.data?.getGroupedReportableDataForBlock?.results ?? []
        ),
      });
    }
  }, [isLoading, data, setAppState]);

  return (
    <AppContext.Provider value={{ ...state, setAppState }}>
      {children}
    </AppContext.Provider>
  );
}

export function useAppState() {
  return useContext(AppContext);
}

export default AppStateProvider;
